import { useState, useCallback, createContext } from 'react'
import dayjs from 'dayjs';
import Day from '../Day'
import styles from './Calendar.module.scss'
import '../globals.scss'
import Search from '../Search'
import Filter from '../Filter';
import { AnimatePresence } from 'framer-motion'

export const FilterContext = createContext({
    filteredFocuses: [],
    setFilteredFocuses: () => {}
})

export default function Calendar() {
    const initialDaysCount = 5;
    const loadBuffer = 5;
    const [isSearchOpen, setIsSearchOpen] = useState(false)
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [filteredFocuses, setFilteredFocuses] = useState([])

    // Save scroll position
    document.addEventListener("DOMContentLoaded", function(event) { 
        var scrollpos = localStorage.getItem('scrollpos');
        if (scrollpos) window.scrollTo(0, scrollpos);
    });

    window.onbeforeunload = function(e) {
        localStorage.setItem('scrollpos', window.scrollY);
    };

    let initialDays = [];
    for (let i = 0; i < initialDaysCount; i++) {
        initialDays.push(dayjs().subtract(i, 'day').startOf('day'));
    }
    const [days, setDays] = useState(initialDays)

    const lastDayRef = useCallback(day => {
        if (day) {
            observer.observe(day)
        }
    }, [days])

    const observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
            // Last element is intersecting, load more days
            const daysToAdd = []
            for (let i = 1; i <= loadBuffer; i++) { // FIX: Changed i from 0 to 1 (else subtracts 0, re-adds last day to days)
                daysToAdd.push(days.slice(-1)[0].subtract(i, 'day'))
            }
            setDays(days.concat(daysToAdd));
        }
    }, {
        rootMargin: "-100px"
    });

    const handleFilter = (e) => {
        e.stopPropagation()
        setIsFilterOpen(prevState => !prevState)
    }

    return (
        <FilterContext.Provider value={{filteredFocuses, setFilteredFocuses}}>
            {/* <p className={styles.comingSoon}>Coming soon! &nbsp;:)</p> */}
            <Search isOpen={isSearchOpen} setIsOpen={setIsSearchOpen}/>
            <div id="controlMenu" className={styles.controlMenu}> 
                <div className={styles.filter} onClick={handleFilter} style={{ mixBlendMode: isSearchOpen ? 'normal' : 'multiply' }}></div>
                <div id="search" className={styles.search} onClick={() => setIsSearchOpen(true)} style={{ mixBlendMode: isSearchOpen ? 'normal' : 'multiply' }} ></div>
            </div>
            <AnimatePresence>
                {isFilterOpen && 
                    <Filter setIsOpen={setIsFilterOpen} />
                }
            </AnimatePresence>
            <div className={styles.container}>
                { days.map((day, i) => {
                    if (i == days.length - 1) {
                        // Last element
                        // Key composed of stringified filter and days, to re-render upon filter change
                        return <Day ref={lastDayRef} className="day" day={day} key={day.toString()} />
                    }
                    lastDayRef.current = null;
                    return <Day className="day" day={day} key={day.toString()} />
                }) }
            </div>
        </FilterContext.Provider>
    );
}