import { useState, useEffect, useRef, useContext } from 'react'
import styles from './SearchHistoryItem.module.scss'
import { getRelativeTime } from './Search'
import { colors } from './Stroll'
import {SelectedVisitContext} from './Search'
import { allFocuses, allHighlights, allStrolls } from './data'
  
export default function SearchHistoryItem({ title, url, visitTimes, gotoItem, selectedItem, setSelectedItem }) {
		let faviconSrc = ''
		if (url.startsWith('https://www.google.com')) {
				faviconSrc = `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${url}/&size=64`
		} else {
			faviconSrc = `https://s2.googleusercontent.com/s2/favicons?domain_url=${url}&sz=64`
		}
		// const [isHovered, setHovered] = useState(false)
		const [numHighlights, setNumHighlights] = useState(0)
		const [captures, setCaptures] = useState([])
		const [visits, setVisits] = useState([])
		const [top, setTop] = useState(0)
		const itemRef = useRef(null)
		const {selectedVisit, setSelectedVisit} = useContext(SelectedVisitContext)
		const [selected, setSelected] = useState(undefined)

		useEffect(() => {
				const pageHighlights = allHighlights.find(highlights => highlights.url == url)
				if (pageHighlights) {
						const num = pageHighlights.highlights.length
						setNumHighlights(num)
				}
				visitTimes.map(visitTime => {
					const stroll = allStrolls.find(stroll => stroll.historyItems.find(item => item.visitTime === visitTime))
					const focus = allFocuses.find(f => f.id === stroll.focusId)
					setVisits(prevVisits => prevVisits.concat([{
							focus: focus,
							strollStartTime: stroll.startTime,
							visitTime: visitTime
					}]))
				})
		}, [])

		useEffect(() => {
			if (selectedVisit && selectedVisit.focus === undefined) { // Fill in focus and strollStartTime
				setSelected(visits.find(visit => visit.visitTime === selectedVisit.visitTime))
			} else if (selectedVisit === undefined) {
				setSelected(undefined)
			}
		}, [selectedVisit])

		useEffect(() => {
			if (selected !== undefined) {
				setSelectedVisit(selected)
			}
		}, [selected])
		
		const handleHover = (e) => {
				setSelectedItem({
						url: url,
						title: title,
						visitTimes: visitTimes
				})
		}

		useEffect(() => {
				setTop(itemRef.current.getBoundingClientRect().top + 18)
		}, [selectedItem])

		const handleGoto = (e) => {
			e.stopPropagation()
			const [visitTime, strollStartTime] = e.currentTarget.id.split('-')
			gotoItem(visitTime, strollStartTime)
		}

		return (
				<div className={styles.container} ref={itemRef} onMouseEnter={handleHover} onMouseLeave={() => setSelectedItem(undefined)} style={{backgroundColor: (selectedItem && selectedItem.visitTimes == visitTimes) ? '#f9f9f9' : ''}}>
							<a className={styles.url} href={url} target="_blank">
								<div className={styles.faviconContainer}>
											{numHighlights > 0 && <div className={styles.numHighlights}>{numHighlights}</div>}
											<img className={styles.favicon} src={faviconSrc} alt="Favicon" />
									</div>
									<p className={styles.title}>{title}</p>
							</a>
						<a className={styles.arrow} href={url} target="_blank" style={{opacity: (selectedItem && selectedItem.visitTimes == visitTimes) ? 1 : 0}}></a>
						<div className={styles.visitsContainer} style={{opacity: (selectedItem && selectedItem.visitTimes == visitTimes) ? 1 : 0, top: top}}>
								{visits.map(visit => {
										return (
												<div
													className={styles.visit} 
													key={`${visit.visitTime}-${visit.strollStartTime}`}
													id={`${visit.visitTime}-${visit.strollStartTime}`} 
													onClick={handleGoto} 
													onMouseEnter={() => {
														document.getElementById(`${visit.visitTime}-${visit.strollStartTime}`).style.opacity = 1;
														setSelected(visit);
													}}
													onMouseLeave={() => {
														document.getElementById(`${visit.visitTime}-${visit.strollStartTime}`).style.opacity = 0.4;
														setSelected(undefined);
													}}
													style={{opacity: (selected && selected.visitTime === visit.visitTime) ? 1 : 0.4}}
												>
														<svg className={styles.strollIcon} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
																<path fillRule="evenodd" clipRule="evenodd" d="M5.81654 11.8954C6.61378 11.8954 7.29864 11.1649 7.59653 10.2542C8.47666 10.7691 9.56237 10.8492 10.164 10.2574C10.7545 9.67665 10.7057 8.61883 10.2307 7.73974C11.2 7.46628 12 6.75504 12 5.92073C12 5.06244 11.1534 4.3344 10.1467 4.07923C10.5694 3.23638 10.6008 2.25643 10.0498 1.69621C9.48036 1.11718 8.4522 1.15286 7.58363 1.60231C7.27916 0.70977 6.60249 0 5.81662 0C5.04009 0 4.37019 0.692992 4.06063 1.57055C3.23454 1.18154 2.29391 1.167 1.74957 1.70238C1.17668 2.26585 1.20553 3.27831 1.64153 4.14096C0.730655 4.43879 0 5.12372 0 5.92107C0 6.67013 0.644832 7.31998 1.47814 7.64287C1.04986 8.48835 1.01553 9.47479 1.56908 10.0376C2.13168 10.6096 3.14192 10.5817 4.0037 10.1476C4.28158 11.1073 4.98843 11.8954 5.81654 11.8954Z" fill={`${colors[visit.focus.color]}`}/>
														</svg>
														<p className={styles.info}>In <span className={styles.focus}>{visit.focus.name}</span> {getRelativeTime(Date.now(), visit.visitTime).toLowerCase()}</p>
												</div>
										)
								})}
						</div>
				</div>
	)
}