import dayjs from "dayjs"

export const allFocuses = [
    {
        name: "Computer vision",
        id: 0,
        color: 0
    },
    {
        name: "Filmmaking",
        id: 1,
        color: 5
    },
    {
        name: "Mindset",
        id: 2,
        color: 2
    },
    {
        name: "Systems",
        id: 3,
        color: 3
    },
    {
        name: "Computational poetry",
        id: 4,
        color: 4
    },
    {
        name: "Tools for thought",
        id: 5,
        color: 4
    },
    {
        name: "Interaction design",
        id: 6,
        color: 7
    },
    {
        name: "Spatial design",
        id: 8,
        color: 6
    },
    {
        name: "Multiplayer tech",
        id: 9,
        color: 1
    },
]

export const allStrolls = [
    {
        focusId: 0, // Computer vision
        startTime: dayjs().subtract(3, 'day').hour(11).minute(0).valueOf(),
        endTime: dayjs().subtract(3, 'day').hour(12 + 1).minute(0).valueOf(),
        historyItems: [
            {
                url: "https://en.wikipedia.org/wiki/Hough_transform",
                title: "Hough transform - Wikipedia",
                visitTime: dayjs().subtract(3, 'day').hour(11).minute(1).valueOf()
            },
            {
                url: "https://docs.opencv.org/3.4/d9/db0/tutorial_hough_lines.html",
                title: "OpenCV: Hough Line Transform",
                visitTime: dayjs().subtract(3, 'day').hour(11).minute(2).valueOf()
            },
            {
                url: "https://opencv24-python-tutorials.readthedocs.io/en/latest/py_tutorials/py_imgproc/py_houghlines/py_houghlines.html",
                title: 'Hough Line Transform — OpenCV-Python Tutorials beta documentation',
                visitTime: dayjs().subtract(3, 'day').hour(11).minute(3).valueOf()
            },
            {
                url: "https://docs.opencv.org/4.x/da/d53/tutorial_py_houghcircles.html",
                title: 'OpenCV: Hough Circle Transform',
                visitTime: dayjs().subtract(3, 'day').hour(11).minute(4).valueOf()
            },
            {
                url: "https://medium.com/@isinsuarici/hough-circle-transform-in-opencv-d74bdf5161ed",
                title: "Hough Circle Transform in OpenCV",
                visitTime: dayjs().subtract(3, 'day').hour(11).minute(5).valueOf()
            }
        ]
    },
	// {
    //     focusId: 0, // Computer vision
    //     startTime: dayjs().subtract(3, 'day').hour(11).minute(0).valueOf(),
    //     endTime: dayjs().subtract(3, 'day').hour(12 + 1).minute(0).valueOf(),
    //     historyItems: [
    //         {
    //             url: "https://homepages.inf.ed.ac.uk/rbf/HIPR2/sobel.htm",
    //             title: 'Feature Detectors - Sobel Edge Detector',
    //             visitTime: dayjs().subtract(3, 'day').hour(11).minute(1).valueOf()
    //         },
    //         {
    //             url: "https://homepages.inf.ed.ac.uk/rbf/HIPR2/canny.htm",
    //             title: 'Feature Detectors - Canny Edge Detector',
    //             visitTime: dayjs().subtract(3, 'day').hour(11).minute(2).valueOf()
    //         }
    //     ]
    // },
	{
        focusId: 0, // Computer vision
        startTime: dayjs().subtract(5, 'day').hour(11).minute(0).valueOf(),
        endTime: dayjs().subtract(5, 'day').hour(12).minute(0).valueOf(),
        historyItems: [
            {
                url: "https://mathworld.wolfram.com/FourierTransform.html",
                title: 'Fourier Transform -- from Wolfram MathWorld',
                visitTime: dayjs().subtract(5, 'day').hour(11).minute(1).valueOf()
            },
            {
                url: "https://www.jezzamon.com/fourier/index.html",
                title: 'An Interactive Introduction to Fourier Transforms',
                visitTime: dayjs().subtract(5, 'day').hour(11).minute(2).valueOf()
            },
            {
                url: "https://brianmcfee.net/dstbook-site/content/ch05-fourier/DFT.html",
                title: '5.5. The Discrete Fourier Transform — Digital Signals Theory',
                visitTime: dayjs().subtract(5, 'day').hour(11).minute(3).valueOf()
            },
						{
                url: "https://brianmcfee.net/dstbook-site/content/ch05-fourier/Examples.html",
                title: '5.6. Examples — Digital Signals Theory',
                visitTime: dayjs().subtract(5, 'day').hour(11).minute(4).valueOf()
            },
						{
                url: "https://brianmcfee.net/dstbook-site/content/ch05-fourier/SummingSinusoids.html",
                title: '5.7. Summing sinusoids — Digital Signals Theory',
                visitTime: dayjs().subtract(5, 'day').hour(11).minute(5).valueOf()
            },
        ]
    },
	{
        focusId: 0, // Computer vision
        startTime: dayjs().subtract(7, 'day').hour(12).minute(0).valueOf(),
        endTime: dayjs().subtract(7, 'day').hour(12 + 2).minute(0).valueOf(),
        historyItems: [
            {
                url: "https://ncase.me/matrix/",
                title: 'THE MAGNIFICENT 2D MATRIX',
                visitTime: dayjs().subtract(7, 'day').hour(12).minute(1).valueOf()
            },
            {
                url: "https://brilliant.org/wiki/affine-transformations/",
                title: 'Affine transformations | Brilliant Math & Science Wiki',
                visitTime: dayjs().subtract(7, 'day').hour(12).minute(2).valueOf()
            },
            {
                url: "https://www.youtube.com/watch?v=kYB8IZa5AuE",
                title: 'Linear transformations and matrices | Chapter 3, Essence of linear algebra - YouTube',
                visitTime: dayjs().subtract(7, 'day').hour(12).minute(3).valueOf()
            },
            {
                url: "https://www.youtube.com/watch?v=XkY2DOUCWMU",
                title: 'Matrix multiplication as composition | Chapter 4, Essence of linear algebra - YouTube',
                visitTime: dayjs().subtract(7, 'day').hour(12).minute(4).valueOf()
            }
        ]
    },
	// {
    //     focusId: 0, // Computer vision
    //     startTime: dayjs().subtract(7, 'day').hour(12).minute(0).valueOf(),
    //     endTime: dayjs().subtract(7, 'day').hour(12 + 2).minute(0).valueOf(),
    //     historyItems: [
    //         {
    //             url: "https://www.youtube.com/watch?v=8rrHTtUzyZA",
    //             title: 'Convolutions in image processing | Grant Sanderson - YouTube',
    //             visitTime: dayjs().subtract(7, 'day').hour(12).minute(1).valueOf()
    //         },
    //         {
    //             url: "https://computationalthinking.mit.edu/Spring21/",
    //             title: 'Introduction to Computational Thinking',
    //             visitTime: dayjs().subtract(7, 'day').hour(12).minute(2).valueOf()
    //         },
    //         {
    //             url: "https://www.youtube.com/watch?v=KuXjwB4LzSA",
    //             title: 'But what is a convolution? - YouTube',
    //             visitTime: dayjs().subtract(7, 'day').hour(12).minute(3).valueOf()
    //         },
    //         {
    //             url: "https://www.youtube.com/watch?v=IaSGqQa5O-M",
    //             title: 'Convolutions | Why X+Y in probability is a beautiful mess - YouTube',
    //             visitTime: dayjs().subtract(7, 'day').hour(12).minute(4).valueOf()
    //         },
    //         {
    //             url: "https://ezyang.github.io/convolution-visualizer/",
    //             title: 'Convolution Visualizer',
    //             visitTime: dayjs().subtract(7, 'day').hour(12).minute(5).valueOf()
    //         }
    //     ]
    // },
	{
        focusId: 6, // Interaction design
        startTime: dayjs().subtract(6, 'day').hour(12 + 3).minute(0).valueOf(),
        endTime: dayjs().subtract(6, 'day').hour(12 + 4).minute(0).valueOf(),
        historyItems: [
            {
                url: "http://worrydream.com/",
                title: 'Bret Victor, beast of burden',
                visitTime: dayjs().subtract(6, 'day').hour(12 + 3).minute(1).valueOf()
            },
            {
                url: "http://worrydream.com/ABriefRantOnTheFutureOfInteractionDesign/",
                title: 'A Brief Rant on the Future of Interaction Design',
                visitTime: dayjs().subtract(6, 'day').hour(12 + 3).minute(2).valueOf()
            },
            {
                url: "http://worrydream.com/ABriefRantOnTheFutureOfInteractionDesign/responses.html",
                title: 'Responses: A Brief Rant on the Future of Interaction Design',
                visitTime: dayjs().subtract(6, 'day').hour(12 + 3).minute(3).valueOf()
            }
        ]
    },
	{
        focusId: 2, // Mindset
        startTime: dayjs().subtract(7, 'day').hour(10).minute(0).valueOf(),
        endTime: dayjs().subtract(7, 'day').hour(12).minute(0).valueOf(),
        historyItems: [
            {
                url: "http://paulgraham.com/greatwork.html",
                title: "How to Do Greak Work",
                visitTime: dayjs().subtract(7, 'day').hour(10).minute(1).valueOf()
            },
            {
                url: "https://www.varghoose.com/p/reflecting-on-2022",
                title: 'Reflecting on 2022 - Michelle Varghoose',
                visitTime: dayjs().subtract(7, 'day').hour(10).minute(2).valueOf()
            },
            {
                url: "https://caseycaruso.medium.com/engineer-by-day-venture-capitalist-by-night-585929430c21",
                title: 'Engineer by Day, Venture Capitalist by Night | by Casey Caruso | Medium',
                visitTime: dayjs().subtract(7, 'day').hour(10).minute(3).valueOf()
            },
            {
                url: "http://paulgraham.com/read.html",
                title: 'The Need to Read',
                visitTime: dayjs().subtract(7, 'day').hour(10).minute(4).valueOf()
            },
        ]
    },
	{
        focusId: 1, // Filmmaking
        startTime: dayjs().subtract(1, 'day').hour(9).minute(0).valueOf(),
        endTime: dayjs().subtract(1, 'day').hour(9).minute(30).valueOf(),
        historyItems: [
            {
                url: "https://www.rogerebert.com/interviews/hayao-miyazaki-interview",
                title: 'Hayao Miyazaki interview | Interviews | Roger Ebert',
                visitTime: dayjs().subtract(1, 'day').hour(9).minute(1).valueOf()
            },
            {
                url: "https://www.nytimes.com/2021/11/23/t-magazine/hayao-miyazaki-studio-ghibli.html",
                title: 'Hayao Miyazaki Prepares to Cast One Last Spell - The New York Times',
                visitTime: dayjs().subtract(1, 'day').hour(9).minute(2).valueOf()
            },
            {
                url: "https://www.jquiambao.com/being-still",
                title: 'Being Still - James Quiambao',
                visitTime: dayjs().subtract(1, 'day').hour(9).minute(3).valueOf()
            },
        ]
    },
	{
        focusId: 3, // Systems
        startTime: dayjs().subtract(5, 'day').hour(12 + 6).minute(0).valueOf(),
        endTime: dayjs().subtract(5, 'day').hour(12 + 7).minute(0).valueOf(),
        historyItems: [
            {
                url: "https://beej.us/guide/bggdb/",
                title: "Beej's Quick Guide to GDB",
                visitTime: dayjs().subtract(5, 'day').hour(12 + 6).minute(1).valueOf()
            },
            {
                url: "https://cs.brown.edu/courses/cs033/docs/guides/gdb.pdf",
                title: "gdb.pdf",
                visitTime: dayjs().subtract(5, 'day').hour(12 + 6).minute(2).valueOf()
            }
        ]
    },
	{
        focusId: 3, // Systems
        startTime: dayjs().subtract(3, 'day').hour(12 + 2).minute(0).valueOf(),
        endTime: dayjs().subtract(3, 'day').hour(12 + 2).minute(30).valueOf(),
        historyItems: [
            {
                url: "https://puri.sm/posts/primer-to-reverse-engineering/",
                title: 'A Primer Guide to Reverse Engineering - Purism',
                visitTime: dayjs().subtract(3, 'day').hour(12 + 2).minute(1).valueOf()
            },
            {
                url: "https://en.wikipedia.org/wiki/FLAGS_register",
                title: "FLAGS register - Wikipedia",
                visitTime: dayjs().subtract(3, 'day').hour(12 + 2).minute(2).valueOf()
            },
						{
                url: "https://en.wikipedia.org/wiki/Carry_flag",
                title: "Carry register - Wikipedia",
                visitTime: dayjs().subtract(3, 'day').hour(12 + 2).minute(3).valueOf()
            },
						{
                url: "https://en.wikipedia.org/wiki/Zero_flag",
                title: "Zero register - Wikipedia",
                visitTime: dayjs().subtract(3, 'day').hour(12 + 2).minute(4).valueOf()
            }
        ]
    },
	{
        focusId: 3, // Systems
        startTime: dayjs().subtract(1, 'day').hour(12 + 6).minute(0).valueOf(),
        endTime: dayjs().subtract(1, 'day').hour(12 + 6).minute(30).valueOf(),
        historyItems: [
            {
                url: "https://www.google.com/search?q=how+to+read+assembly",
                title: 'how to read assembly - Google Search',
                visitTime: dayjs().subtract(2, 'day').hour(12 + 6).minute(1).valueOf()
            },
            {
                url: "http://www.jagregory.com/abrash-zen-of-asm/",
                title: 'Zen of Assembly Language: Volume I, Knowledge',
                visitTime: dayjs().subtract(2, 'day').hour(12 + 6).minute(2).valueOf()
            },
            {
                url: "https://diveintosystems.org/book/C9-ARM64/functions.html",
                title: 'Dive Into Systems',
                visitTime: dayjs().subtract(2, 'day').hour(12 + 6).minute(3).valueOf()
            },
            {
                url: "https://www.timdbg.com/posts/fakers-guide-to-assembly/",
                title: "The faker's guide to reading (x86) assembly language | TimDbg",
                visitTime: dayjs().subtract(2, 'day').hour(12 + 6).minute(4).valueOf()
            }
        ]
	},
	{
        focusId: 4, // Computational poetry
        startTime: dayjs().subtract(4, 'day').hour(12 + 8).minute(0).valueOf(),
        endTime: dayjs().subtract(4, 'day').hour(12 + 8).minute(30).valueOf(),
        historyItems: [
			{
                url: "https://en.wikipedia.org/wiki/Digital_poetry",
                title: "Digital poetry - Wikipedia",
                visitTime: dayjs().subtract(4, 'day').hour(12 + 8).minute(1).valueOf()
	        },
			{
                url: "https://en.wikipedia.org/wiki/Computational_creativity#Poetry",
                title: "Computational creativity - Wikipedia",
                visitTime: dayjs().subtract(4, 'day').hour(12 + 8).minute(2).valueOf()
	        },
			{
                url: "https://en.wikipedia.org/wiki/Synthetic_media",
                title: "Synthetic media - Wikipedia",
                visitTime: dayjs().subtract(4, 'day').hour(12 + 8).minute(3).valueOf()
	        },
        ]
    },
	{
        focusId: 5, // Tools for thought
        startTime: dayjs().subtract(7, 'day').hour(9).minute(0).valueOf(),
        endTime: dayjs().subtract(7, 'day').hour(10).minute(0).valueOf(),
        historyItems: [
            {
                url: "https://www.notion.so/blog/topic/podcast",
                title: "Podcast - Tools & Craft - Notion",
                visitTime: dayjs().subtract(7, 'day').hour(9).minute(1).valueOf()
            },
            {
                url: "https://www.notion.so/blog/andy-matuschak",
                title: 'Andy Matuschak on physically-informed digital interface design',
                visitTime: dayjs().subtract(7, 'day').hour(9).minute(2).valueOf()
            },
        ]
    },
	{
        focusId: 5, // Tools for thought
        startTime: dayjs().subtract(3, 'day').hour(12 + 7).minute(0).valueOf(),
        endTime: dayjs().subtract(3, 'day').hour(12 + 8).minute(0).valueOf(),
        historyItems: [
            {
                url: "https://www.robinsloan.com/notes/home-cooked-app/",
                title: 'An app can be a home-cooked meal',
                visitTime: dayjs().subtract(3, 'day').hour(12 + 7).minute(1).valueOf()
            },
            {
                url: "https://www.robinsloan.com/notes/",
                title: 'Notes, blog posts, etc.',
                visitTime: dayjs().subtract(3, 'day').hour(12 + 7).minute(2).valueOf()
            },
            {
                url: "https://www.robinsloan.com/notes/making-culture-internets/",
                title: 'Making culture for the internets',
                visitTime: dayjs().subtract(3, 'day').hour(12 + 7).minute(3).valueOf()
            },
            {
                url: "https://www.robinsloan.com/notes/how-to-end/",
                title: 'How to end on the internet',
                visitTime: dayjs().subtract(3, 'day').hour(12 + 7).minute(4).valueOf()
            }
        ]
    },
	{
        focusId: 5, // Tools for thought
        startTime: dayjs().subtract(1, 'day').hour(12 + 1).minute(0).valueOf(),
        endTime: dayjs().subtract(1, 'day').hour(12 + 2).minute(0).valueOf(),
        historyItems: [
            {
                url: "https://early.khanacademy.org/",
                title: 'Khan Academy Early Product Development and Long-term Research',
                visitTime: dayjs().subtract(2, 'day').hour(12 + 1).minute(1).valueOf()
            },
            {
                url: "https://early.khanacademy.org/cantor/",
                title: 'Numbers at play: dynamic toys make the invisible visible | Khan Academy Long-term Research',
                visitTime: dayjs().subtract(2, 'day').hour(12 + 1).minute(2).valueOf()
            },
            {
                url: "https://medium.com/khan-academy-early-product-development/looking-sideways-at-the-design-squiggle-9bab49d1c2de",
                title: 'Looking sideways at the design squiggle | Khan Academy Early Product Development',
                visitTime: dayjs().subtract(2, 'day').hour(12 + 1).minute(3).valueOf()
            },
        ]
    },
		{
        focusId: 8, // Spatial design
        startTime: dayjs().subtract(3, 'day').hour(12 + 3).minute(0).valueOf(),
        endTime: dayjs().subtract(3, 'day').hour(12 + 5).minute(0).valueOf(),
        historyItems: [
            {
                url: "https://www.google.com/search?q=config+spatial+interfaces",
                title: 'config+spatial interfaces - Google Search',
                visitTime: dayjs().subtract(3, 'day').hour(12 + 3).minute(1).valueOf()
            },
            {
                url: "https://www.youtube.com/watch?v=H4LosXgKX4g",
                title: 'Exploring spatial software - John Palmer (Config 2021) - YouTube',
                visitTime: dayjs().subtract(3, 'day').hour(12 + 3).minute(2).valueOf()
            },
            {
                url: "https://darkblueheaven.com/spatialinterfaces/",
                title: 'Spatial Interfaces | Dark Blue Heaven',
                visitTime: dayjs().subtract(3, 'day').hour(12 + 3).minute(3).valueOf()
            },
            {
                url: "https://darkblueheaven.com/spatialsoftware/",
                title: 'Spatial Software | Dark Blue Heaven',
                visitTime: dayjs().subtract(3, 'day').hour(12 + 3).minute(4).valueOf()
            }
        ]
    },
		{
        focusId: 8, // Spatial design
        startTime: dayjs().subtract(2, 'day').hour(12 + 2).minute(0).valueOf(),
        endTime: dayjs().subtract(2, 'day').hour(12 + 4).minute(0).valueOf(),
        historyItems: [
            {
                url: "https://www.youtube.com/watch?v=M0UGgkehefM",
                title: 'Designing spatial software - Suraya Shivji (Config 2022) - YouTube',
                visitTime: dayjs().subtract(2, 'day').hour(12 + 2).minute(1).valueOf()
            },
            {
                url: "https://www.figma.com/community/file/1105987623902421178",
                title: 'Designing spatial software (Config 2022) - Figma',
                visitTime: dayjs().subtract(2, 'day').hour(12 + 2).minute(2).valueOf()
            }
        ]
    },
		{
        focusId: 9, // Multiplayer tech
        startTime: dayjs().subtract(4, 'day').hour(12 + 9).minute(0).valueOf(),
        endTime: dayjs().subtract(4, 'day').hour(12 + 10).minute(0).valueOf(),
        historyItems: [
            {
                url: "https://www.figma.com/blog/",
                title: 'Figma Blog | Shortcut',
                visitTime: dayjs().subtract(4, 'day').hour(12 + 9).minute(1).valueOf()
            },
            {
                url: "https://www.figma.com/blog/how-figmas-multiplayer-technology-works/",
                title: 'How Figma\'s multiplayer technology works | Figma Blog',
                visitTime: dayjs().subtract(4, 'day').hour(12 + 9).minute(2).valueOf()
            },
            {
                url: "https://www.figma.com/blog/making-multiplayer-more-reliable/",
                title: 'Making multiplayer more reliable | Figma Blog',
                visitTime: dayjs().subtract(4, 'day').hour(12 + 9).minute(3).valueOf()
            }
        ]
    }
]

export const allHighlights = [
    { // Hough transforms
        url: "https://docs.opencv.org/3.4/d9/db0/tutorial_hough_lines.html",
        highlights: [
			{
	           createdTime: 123,
	           text: "The more curves intersecting means that the line represented by that intersection have more points. In general, we can define a threshold of the minimum number of intersections needed to detect a line."
	        }
		]
    },
    { // How to do great work
        url: "http://paulgraham.com/greatwork.html",
        highlights: [
			{
            createdTime: 123,
            text: "What are you excessively curious about — curious to a degree that would bore most other people? That's what you're looking for."
	        },
			{
            createdTime: 123,
            text: "Knowledge expands fractally, and from a distance its edges look smooth, but once you learn enough to get close to one, they turn out to be full of gaps."
	        },
			{
            createdTime: 123,
            text: "It's good to know about multiple things; some of the biggest discoveries come from noticing connections between different fields."
	        }
		]
    },
    { // Reflecting on 2022 - Michelle Varghoose
        url: "https://www.varghoose.com/p/reflecting-on-2022",
        highlights: [
			{
            createdTime: 123,
            text: "One thing I know for sure is I want to invest as much time as possible with the people I love."
	        }
		]
    },
    { // Engineer by Day, VC by Night
        url: "https://caseycaruso.medium.com/engineer-by-day-venture-capitalist-by-night-585929430c21",
        highlights: [
			{
            createdTime: 123,
            text: "I also don\'t think about my jobs as work. I think of them as structured ways to explore my interests."
	        }
		]
    },
    { // The Need to Read
        url: "http://paulgraham.com/read.html",
        highlights: [
			{
            createdTime: 123,
            text: "writing is not just a way to convey ideas, but also a way to have them."
	        }
		]
    },
    { // Andy Matuschak's Tools & Craft episode
        url: "https://www.notion.so/blog/andy-matuschak",
        highlights: [
			{
            createdTime: 123,
            text: "A thing that people with physical studios and workspaces sometimes do is to print out inspiration and put it all around them. And I like imagining that you could do that, but have it be constantly shifting, almost like the seasons, like the trees around you changing."
	        }
		]
    },
    { // Spatial interfaces
        url: "https://darkblueheaven.com/spatialinterfaces/",
        highlights: [
			{
            createdTime: 123,
            text: "I could place my personal site on a street near the websites of my friends. We could form a little village. I could then go to my favorite sites and walk around nearby to find sites like them."
	        }
		]
    },
    { // Future of IxD
        url: "http://worrydream.com/ABriefRantOnTheFutureOfInteractionDesign/",
        highlights: [
			{
            createdTime: 123,
            text: "Our hands feel things, and our hands manipulate things. Why aim for anything less than a dynamic medium that we can see, feel, and manipulate?"
	        }
		]
    },
    { // Hayao Miyazaki interview
        url: "https://www.rogerebert.com/interviews/hayao-miyazaki-interview",
        highlights: [
			{
            createdTime: 123,
            text: "He clapped his hands three or four times. \"The time in between my clapping is ma. If you just have non-stop action with no breathing space at all, it's just busyness, But if you take a moment, then the tension building in the film can grow into a wider dimension.\""
	        },
			{
            createdTime: 123,
            text: "But just because it's 80 percent intense all the time doesn't mean the kids are going to bless you with their concentration. What really matters is the underlying emotions--that you never let go of those."
	        }
		]
    },
    { // Computational creativity
        url: "https://en.wikipedia.org/wiki/Computational_creativity#Poetry",
        highlights: [
			{
            createdTime: 123,
            text: "To design programs that can enhance human creativity without necessarily being creative themselves."
	        }
		]
    },

]

export const allCaptures = [
    { // Hough transforms
        url: "https://opencv24-python-tutorials.readthedocs.io/en/latest/py_tutorials/py_imgproc/py_houghlines/py_houghlines.html",
        captures: [
			{
	           createdTime: 123,
	           dataUrl: "hough-transforms-1",
	           backgroundColor: "#FFFFFF"
	        }
		]
    },
    { // Fourier transforms
        url: "https://www.jezzamon.com/fourier/index.html",
        captures: [
			{
	           createdTime: 123,
	           dataUrl: "fourier-transforms-1",
	           backgroundColor: "#FFFFFF"
	        },
			{
	           createdTime: 123,
	           dataUrl: "fourier-transforms-2",
	           backgroundColor: "#FFFFFF"
	        }
		]
    },
    { // More Fourier transforms!
        url: "https://brianmcfee.net/dstbook-site/content/ch05-fourier/DFT.html",
        captures: [
			{
	           createdTime: 123,
	           dataUrl: "dft",
	           backgroundColor: "#FFFFFF"
	        }
		]
    },
    { // Affine transforms
        url: "https://ncase.me/matrix/",
        captures: [
			{
	           createdTime: 123,
	           dataUrl: "affine",
	           backgroundColor: "#FFFFFF"
	        }
		]
    },
    { // Notion Tools & Craft
        url: "https://www.notion.so/blog/andy-matuschak",
        captures: [
			{
	           createdTime: 123,
	           dataUrl: "andy-notes",
	           backgroundColor: "#FFFFFF"
	        }
		]
    },
    { // Khan Academy: Numbers at play
        url: "https://early.khanacademy.org/cantor/",
        captures: [
			{
	           createdTime: 123,
	           dataUrl: "numbers-in-many-ways",
	           backgroundColor: "#FFFFFF"
	        }
		]
    },
    { // Figma's multiplayer tech
        url: "https://www.figma.com/blog/how-figmas-multiplayer-technology-works/",
        captures: [
			{
	           createdTime: 123,
	           dataUrl: "figma-1",
	           backgroundColor: "#000000"
	        },
					{
	           createdTime: 123,
	           dataUrl: "figma-2",
	           backgroundColor: "#FFFFFF"
	        }
		]
    }
]