import HistoryItem from "./HistoryItem";
import styles from './Stroll.module.scss';
import { useState, useRef } from 'react'
import { motion } from 'framer-motion'
import dayjs from "dayjs";
import { allFocuses, allStrolls } from './data'

export const colors = ['#FFCF7B', '#e8a6f5', '#FBAECA', '#FBC4B0', '#FFA48F', '#FFB5C2', '#FFCFED', '#FFD9CB']

export default function Stroll({ stroll, remove, rename }) {
  const [focus, setFocus] = useState(allFocuses.find(f => f.id === stroll.focusId))
  const optionsMenuRef = useRef(null)
  const titleRef = useRef(null)
  const timestampRef = useRef(null)
  const titleContainerRef = useRef(null)
  const [historyItemsHeight, setHistoryItemsHeight] = useState(undefined)
  const historyItemsRef = useRef(null)

  let startTime = dayjs(stroll.startTime).format('h A')
  let endTime = stroll.endTime === -1 ? dayjs().format('h A') : dayjs(stroll.endTime).format('h A')
  const timestamp = startTime === endTime ? startTime : `${startTime} - ${endTime}`

  const handleToggleClick = (e) => {
    if (e.target.id !== 'optionsMenu' && e.target.nodeName !== "INPUT" && e.target.id !== 'save') {
      // Valid click
      if (historyItemsHeight === undefined) {
        if (historyItemsRef.current.style.maxHeight === '') {
          // Initial dropdown open, unknown height of history items
          // Note: transition must be defined in CSS to animate
          historyItemsRef.current.style.transition = '' // Temporarily remove transition
          historyItemsRef.current.style.maxHeight = '100%'
          historyItemsRef.current.style.height = 'auto'
          const height = historyItemsRef.current.offsetHeight

          setHistoryItemsHeight(height) // Set known height for future use
          historyItemsRef.current.style.maxHeight = '0px'

          // Manually animate max-height
          // Note: max-height is only animatable when set to fixed values, height not animatable
          const iterations = 30
          for (let i = 1; i <= iterations; i++) {
            setTimeout(() => { 
              historyItemsRef.current.style.maxHeight = `${(height / iterations) * i}px`
            }, 10)
          }

          historyItemsRef.current.style.transition = '0.3s ease-in-out' // Restore transition for future toggles
          historyItemsRef.current.style.opacity = 1
        } else {
          // Manually close dropdown
          const height = historyItemsRef.current.offsetHeight
          setHistoryItemsHeight(height) // Set known height for future use

          // Manually animate max-height
          const iterations = 30
          for (let i = iterations - 1; i >= 0; i--) {
            setTimeout(() => { 
              historyItemsRef.current.style.maxHeight = `${(height / iterations) * i}px`
            }, 10)
          }
          historyItemsRef.current.style.opacity = 0
        }
      }
      else if (historyItemsRef.current.style.maxHeight === '0px') {
        // Subsequent dropdown open, known height of history items
        historyItemsRef.current.style.maxHeight = `${historyItemsHeight}px`
        historyItemsRef.current.style.opacity = 1
      }
      else {
        // Close dropdown
        historyItemsRef.current.style.maxHeight = '0px'
        historyItemsRef.current.style.opacity = 0
      }
    }
  }

  const captureClick = (e) => {
    e.stopPropagation()
  }

  const handleRename = (e) => {
    e.stopPropagation()
    optionsMenuRef.current.innerHTML = ''

    const currentFocus = titleRef.current.textContent
    titleRef.current.innerHTML = ''

    const inputContainer = document.createElement('div')
    inputContainer.className = styles.inputContainer

    const input = document.createElement('input')
    input.value = currentFocus
    input.className = styles.strollNameInput
    input.focus()
    input.maxLength = 40
    input.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        executeRename()
      }
    })
    
    const save = document.createElement('div')
    save.className = styles.saveInput
    save.id = 'save'

    const closeRename = (e) => {
      if (e.target != input && e.target != save) {
        // Close rename view upon external click
        titleRef.current.innerHTML = ''
        titleRef.current.textContent = focus.name // Restore previous focus
        timestampRef.current.style.display = ''
      }
      document.body.removeEventListener('click', closeRename)
    }

    document.body.addEventListener('click', closeRename)

    const executeRename = () => {
      document.body.removeEventListener('click', closeRename)
      const name = input.value

      if (name != focus.name) {
        // Focus name changed
        const focuses = allFocuses
        const prevFocusId = stroll.focusId
        let f = focuses.find(fo => fo.name === name)
        let updatedFocuses = focuses
        let focusId = f ? f.id : Date.now()
  
          if (!f) {
            // New focus
            f = {
              name: name,
              id: focusId,
              color: focus.color // Keep same color
            }
            updatedFocuses = [
              ...focuses,
              f
            ]
          }
          const strolls = allStrolls
          if (!strolls.find(s => stroll.startTime !== s.startTime && prevFocusId === s.focusId)) {
            // Focus has no corresponding strolls anymore -> Delete focus
            updatedFocuses = updatedFocuses.filter(fo => fo.id !== prevFocusId)
          }

          setFocus(f)
          rename(stroll, f.id)
          titleRef.current.innerHTML = ''
          titleRef.current.textContent = input.value
          timestampRef.current.style.display = ''
      } else {
        // No updates necessary
        titleRef.current.innerHTML = ''
        titleRef.current.textContent = input.value
        timestampRef.current.style.display = ''
      }
    }

    save.addEventListener('click', () => {
      e.stopPropagation()
      executeRename()
    })

    inputContainer.appendChild(input)
    inputContainer.appendChild(save)

    titleRef.current.appendChild(inputContainer)
    input.focus()
    timestampRef.current.style.display = 'none'
  }

  const handleDelete = (e) => {
    e.stopPropagation()
    optionsMenuRef.current.innerHTML = ''
    remove(stroll)
  }

  const handleOptionsClick = (e) => {    
    e.currentTarget.innerHTML = ''

    const renameBtn = document.createElement('div')
    const renameIcon = document.createElement('div')
    const renameText = document.createElement('p')
    renameText.textContent = 'Rename'
    renameBtn.className = styles.option
    renameIcon.className = styles.renameIcon
    renameBtn.appendChild(renameIcon)
    renameBtn.appendChild(renameText)
    renameBtn.addEventListener('click', handleRename)

    const deleteBtn = document.createElement('div')
    const deleteIcon = document.createElement('div')
    const deleteText = document.createElement('p')
    deleteText.textContent = 'Delete'
    deleteBtn.classList.add(styles.option)
    deleteBtn.classList.add(styles.delete)
    deleteIcon.className = styles.deleteIcon
    deleteBtn.appendChild(deleteIcon)
    deleteBtn.appendChild(deleteText)
    deleteBtn.addEventListener('click', handleDelete)
    
    const optionsContainer = document.createElement('div')
    optionsContainer.className = styles.optionsContainer
    optionsContainer.appendChild(renameBtn)
    optionsContainer.appendChild(deleteBtn)
    optionsContainer.addEventListener('click', captureClick)

    e.currentTarget.appendChild(optionsContainer)
  }

  return (
    <>
      {stroll.historyItems.length > 0 && focus &&
        <motion.div
          className={styles.headerContainer}
          onClick={handleToggleClick}
          onMouseEnter={() => {
            if (optionsMenuRef.current.style.opacity === 0) {
              optionsMenuRef.current.style.opacity = 0.4 
            }
          }}
          onMouseLeave={() => {
            optionsMenuRef.current.style.opacity = 0;
            optionsMenuRef.current.innerHTML = ''
          }}
        >
          <div className={styles.titleContainer} ref={titleContainerRef}>
            <div
              id="optionsMenu"
              onMouseEnter={() => {optionsMenuRef.current.style.opacity = 1 }} 
              ref={optionsMenuRef}
              className={styles.optionsMenu}
              onClick={handleOptionsClick}></div>
              <svg className={styles.strollIcon} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.81654 11.8954C6.61378 11.8954 7.29864 11.1649 7.59653 10.2542C8.47666 10.7691 9.56237 10.8492 10.164 10.2574C10.7545 9.67665 10.7057 8.61883 10.2307 7.73974C11.2 7.46628 12 6.75504 12 5.92073C12 5.06244 11.1534 4.3344 10.1467 4.07923C10.5694 3.23638 10.6008 2.25643 10.0498 1.69621C9.48036 1.11718 8.4522 1.15286 7.58363 1.60231C7.27916 0.70977 6.60249 0 5.81662 0C5.04009 0 4.37019 0.692992 4.06063 1.57055C3.23454 1.18154 2.29391 1.167 1.74957 1.70238C1.17668 2.26585 1.20553 3.27831 1.64153 4.14096C0.730655 4.43879 0 5.12372 0 5.92107C0 6.67013 0.644832 7.31998 1.47814 7.64287C1.04986 8.48835 1.01553 9.47479 1.56908 10.0376C2.13168 10.6096 3.14192 10.5817 4.0037 10.1476C4.28158 11.1073 4.98843 11.8954 5.81654 11.8954Z" fill={`${colors[focus.color]}`}/>
              </svg>
            <a className={styles.strollName} ref={titleRef} >{focus.name}</a>
          </div>
          <p className={styles.timestamp} ref={timestampRef}>{timestamp}</p>
        </motion.div>
      }
        {focus && 
          <div
            className={styles.historyItemsWrapper}
            ref={historyItemsRef}
            id={`stroll-${stroll.startTime}`}
          >
            <div className={styles.bar} style={{ backgroundColor: colors[focus.color] }} ></div>
            <div className={styles.historyItemsContainer}>
            {stroll.historyItems && stroll.historyItems.map((historyItem, i) => {
              return (
                  <HistoryItem title={historyItem.title} key={historyItem.visitTime} url={historyItem.url} visitTime={historyItem.visitTime} startTime={stroll.startTime} endTime={stroll.endTime} />
              )
            })
            }
            </div>
          </div>  
        }
    </>
  )
}