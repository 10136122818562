import { useState, useRef, useEffect, createContext } from 'react'
import styles from './Search.module.scss'
import SearchHistoryItem from './SearchHistoryItem'
import { allStrolls } from './data'

export const SelectedVisitContext = createContext({
	focus: undefined,
	strollStartTime: undefined,
	visitTime: undefined
})

export function getRelativeTime(now, then) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
	var msPerDay = msPerHour * 24;
	var msPerWeek = msPerDay * 7;
	var msPerMonth = msPerDay * 30;
	var msPerYear = msPerDay * 365;

	var elapsed = now - then;

	if (elapsed < msPerDay ) {
		if (Math.floor(elapsed/msPerHour) === 0) {
			return 'Less than an hour ago';
		}
		if (Math.floor(elapsed/msPerHour) === 1) {
			return Math.floor(elapsed/msPerHour) + ' hour ago';   
		}
		return Math.floor(elapsed/msPerHour) + ' hours ago';  
	}

	else if (elapsed < msPerWeek) {
		if (Math.floor(elapsed/msPerDay) === 1) {
		return Math.floor(elapsed/msPerDay) + ' day ago';   
		}
		return Math.floor(elapsed/msPerDay) + ' days ago';   
	}

	else if (elapsed < msPerMonth) {
		if (Math.floor(elapsed/msPerWeek) === 1) {
		return Math.floor(elapsed/msPerWeek) + ' week ago';   
		}
		return Math.floor(elapsed/msPerDay) + ' weeks ago';   
	}

	else if (elapsed < msPerYear) {
		if (Math.floor(elapsed/msPerMonth) === 1) {
		return Math.floor(elapsed/msPerMonth) + ' month ago';   
		}
		return Math.floor(elapsed/msPerMonth) + ' months ago';   
	}

	else {
		if (Math.floor(elapsed/msPerYear) === 1) {
		return Math.floor(elapsed/msPerYear) + ' year ago';   
		}
		return Math.floor(elapsed/msPerYear) + ' years ago';   
	}
}

export default function Search({isOpen, setIsOpen}) {
	const [historyItems, setHistoryItems] = useState([])
	const [suggestedItems, setSuggestedItems] = useState([])
	const [selectedItem, setSelectedItem] = useState(undefined)
	const [selectedVisit, setSelectedVisit] = useState(undefined)
	const [isItemHovered, setIsItemHovered] = useState(false)

	const inputRef = useRef(null)

	useEffect(() => {
		let allItems = []
		allStrolls.map(stroll => {
			allItems = allItems.concat(stroll.historyItems)
		})
		setHistoryItems(allItems)
	}, [])
	
	document.addEventListener('keydown', (e) => {
		if (e.key === ' ' && e.metaKey && e.shiftKey) {
			e.preventDefault()
			setIsOpen(!isOpen)
		}
	})

	const handleExternalClick = (e) => {
		if (e.target == e.currentTarget) {
			setIsOpen(false)
			setSuggestedItems([])
		}
	}

	const handleInputChange = () => {
		const query = inputRef.current.value.trim().toLowerCase()
		setSelectedItem(undefined) // Reset selected item
		setSelectedVisit(undefined) // Reset selected visit
		if (query.length === 0) {
			setSuggestedItems([])
		} else {
			let relevantHistoryItems = historyItems.filter(historyItem => {
				const strippedTitle = historyItem.title.trim().toLowerCase()
				// Query is a substring of words in the title
				return strippedTitle.startsWith(query) || strippedTitle.includes(` ${query}`)
			})
			const timestampItems = []
			relevantHistoryItems = relevantHistoryItems.sort((a, b) => b.visitTime - a.visitTime) // Sort by most recent first
			relevantHistoryItems.map(item => {
				const existingItemTime = Object.keys(timestampItems).find(time => timestampItems[time].find(i => i.url.split('#')[0].split('&')[0] == item.url.split('#')[0].split('&')[0]))
				if (existingItemTime) {
					timestampItems[existingItemTime] = timestampItems[existingItemTime].map(i => {
						if (i.url.split('#')[0].split('&')[0] == item.url.split('#')[0].split('&')[0]) {
							// Same url, update search item with new time
							return {
								url: i.url.split('#')[0].split('&')[0],
								title: i.title,
								visitTimes: i.visitTimes.concat([item.visitTime]) // Append new visit time
							}
						}
						return i
					})
				} else {
					const relativeTime = getRelativeTime(Date.now(), item.visitTime)
					if (timestampItems[relativeTime]) {
						// Existing time category
						const newItem = {
							url: item.url,
							title: item.title,
							visitTimes: [item.visitTime]
						}
						timestampItems[relativeTime] = timestampItems[relativeTime].concat([newItem])
					} else {
						// New time category
						const newItem = {
							url: item.url,
							title: item.title,
							visitTimes: [item.visitTime]
						}
						timestampItems[relativeTime] = [newItem]
					}
				}
			})
			setSuggestedItems(timestampItems)
		}
	}

	const handleGoto = (visitTime, strollStartTime) => {
		setIsOpen(false)
		setSuggestedItems([])
		let stroll = document.getElementById(`stroll-${strollStartTime}`)

		setTimeout(() => {
			while (!stroll) {
				document.body.scrollTo({
					top: 0,
					left: -1,
					behavior: "smooth",
				});
				stroll = document.getElementById(`stroll-${strollStartTime}`)
			}
			
			// stroll.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' })
			stroll.style.transition = '' // Temporarily remove transition
			stroll.style.maxHeight = '100%'
			stroll.style.height = 'auto'
			const height = stroll.offsetHeight
			stroll.style.maxHeight = '0px'

			// Manually animate max-height
			// Note: max-height is only animatable when set to fixed values, height not animatable
			const iterations = 30
			for (let i = 1; i <= iterations; i++) {
				setTimeout(() => { 
					stroll.style.maxHeight = `${(height / iterations) * i}px`
				}, 10)
			}

			stroll.style.transition = '0.3s ease-in-out' // Restore transition for future toggles
			stroll.style.opacity = 1

			setTimeout(() => {
				const historyItem = document.getElementById(`item-${visitTime}`)
				historyItem.scrollIntoView({ block: 'end', inline: 'center', behavior: 'smooth' })
				setTimeout(() => {
					historyItem.style.backgroundColor = '#fffece'
					setTimeout(() => {
						historyItem.style.backgroundColor = ''
					}, 1000)
				}, 1000)
			}, 600)
		}, 300)
	}

	const handleKeyDown = (e) => {
		if (e.key === 'ArrowUp') {
			e.preventDefault() // Prevent up arrow from registering as input
			if (selectedItem && selectedVisit) { // Navigate through visits
				const index = selectedItem.visitTimes.findIndex(time => time === selectedVisit.visitTime)
				if (index !== 0) { // If selected visit is not the first visit...
					setSelectedVisit({ // Select previous visit
						focus: undefined,
						strollStartTime: undefined,
						visitTime: selectedItem.visitTimes[index - 1]
					})
					return
				}
			} 
			// Navigate through search items
			const timeslots = Object.keys(suggestedItems)
			let items = []
			timeslots.map(time => {
				items = items.concat(suggestedItems[time])
			})
			setSelectedVisit(undefined)
			if (selectedItem && items.length > 0) { // Suggested items and selected item exist
				const index = items.findIndex(item => item.visitTimes == selectedItem.visitTimes) // Grab index of selected item
				if (index !== -1 && index !== 0) { // If selected item is not the first suggested item... 
					setSelectedItem(items[index - 1]) // select the previous suggested item
				} else if (index === 0) { // Otherwise...
					setSelectedItem(undefined) // unselect focus and return to input field
				}
			}
		} else if (e.key === 'ArrowDown') {
			e.preventDefault() // Prevent down arrow from registering as input
			if (selectedItem && selectedVisit) { // Navigate through visits
				const index = selectedItem.visitTimes.findIndex(time => time === selectedVisit.visitTime)
				if (index < selectedItem.visitTimes.length - 1) { // If selected visit is not the last visit...
					setSelectedVisit({ // Select next visit
						focus: undefined,
						strollStartTime: undefined,
						visitTime: selectedItem.visitTimes[index + 1]
					})
					return
				}
			}
			// Navigate through search items
			const timeslots = Object.keys(suggestedItems)
			let items = []
			timeslots.map(time => {
				items = items.concat(suggestedItems[time])
			})
			if (!selectedItem && items.length > 0) {
				setSelectedItem(items[0]) // Set selected item to first suggested item, if it exists
				setSelectedVisit(undefined)
			} else if (items.length > 0) { // Suggested items and selected item exist
				const index = items.findIndex(item => item.visitTimes == selectedItem.visitTimes) // Grab index of selected item
				if (index !== -1 && index < items.length - 1) { // If selected item is not the last suggested item...
					setSelectedItem(items[index + 1])	// select the next suggested item
					setSelectedVisit(undefined)
				}
			}
		} else if (e.key === 'ArrowLeft' && selectedItem) { // Unselect visit
			e.preventDefault()
			if (selectedVisit === undefined) return // Avoid unnecessarily setting state
			setSelectedVisit(undefined)
		} else if (e.key === 'ArrowRight' && selectedItem) { // Select first visit
			e.preventDefault()
			if (selectedVisit !== undefined) return // Avoid unnecessarily setting state
			setSelectedVisit({
				focus: undefined, // Later filled in by search history item
				strollStartTime: undefined, // Later filled in by search history item
				visitTime: selectedItem.visitTimes[0]
			})
		} else if (e.key === 'Enter') {
			e.preventDefault()
			if (selectedVisit) {
				// Go to item in calendar
				handleGoto(selectedVisit.visitTime, selectedVisit.strollStartTime)
			} else {
				// Open link in new tab
				window.open(selectedItem.url, '_blank');
			}
		}
	}

  return (
    isOpen && (
		<SelectedVisitContext.Provider value={{selectedVisit, setSelectedVisit}}>
		<div className={styles.wrapper} onClick={handleExternalClick}>
			<div className={styles.container}>
				<input autoFocus type="text" className={styles.input} ref={inputRef} onKeyDown={handleKeyDown} onChange={handleInputChange} placeholder='What would you like to revisit?' autoComplete='off'/>
				{suggestedItems && Object.keys(suggestedItems).length > 0 &&
					<div className={styles.suggestedItemsContainer}>
						{Object.keys(suggestedItems).map(time => {
							return (
								<div className={styles.suggestedCluster} key={time}>
									<p className={styles.time}>{time}</p>
									{suggestedItems[time].map(item => {
										return <SearchHistoryItem key={item.visitTimes} title={item.title} url={item.url} visitTimes={item.visitTimes} gotoItem={handleGoto} selectedItem={selectedItem} setSelectedItem={setSelectedItem} selectedVisit={selectedVisit} setSelectedVisit={setSelectedVisit} />
									})}
								</div>
							)
						})}
						<div className={styles.gradient}></div>
					</div>
				}
			</div>
    </div>
		</SelectedVisitContext.Provider>
		)
  )
}
