import dayjs from "dayjs";
import { useState, forwardRef, useEffect, useContext } from 'react'
import styles from './Day.module.scss'
import Stroll from './Stroll'
import { FilterContext } from './pages/Calendar'
import { allStrolls } from './data'

const Day = forwardRef(({ day }, ref) => {
  const dayObj = dayjs(day);
  const dayFormatted = dayObj.format('MMM D')
  const year = dayObj.format('YYYY')
  const [strolls, setStrolls] = useState([])
  const [strollsToday, setStrollsToday] = useState([])
  const {filteredFocuses, setFilteredFocuses} = useContext(FilterContext)

  useEffect(() => {
    const startOfDay = dayjs(day).startOf('day').valueOf()
    const endOfDay = dayjs(day).endOf('day').valueOf()
    
    const strollsTodayRetrieved = allStrolls.filter(stroll => {
        return ((stroll.startTime >= startOfDay && stroll.startTime < endOfDay) || 
                (stroll.startTime < startOfDay && stroll.endTime >= startOfDay))
    }).sort((a, b) => a.startTime - b.startTime)
    setStrollsToday(strollsTodayRetrieved)
  }, [])

  useEffect(() => {
    const filterFocusIds = filteredFocuses.map(focus => focus.id) // Grab focus ids for quicker lookup
    const filteredStrolls = 
      filterFocusIds.length > 0 ? // If there are focuses to filter...
        strollsToday.filter(stroll => filterFocusIds.includes(stroll.focusId)) // filter them!
      : strollsToday // Otherwise, return all strolls today
  
    setStrolls(filteredStrolls)
  }, [filteredFocuses, strollsToday]) // Update strolls when focuses to filter change

  const remove = (strollToRemove) => {
    setStrolls(prevStrolls => prevStrolls.filter(stroll => stroll.startTime !== strollToRemove.startTime))
    // const strollsRetrieved = allStrolls
    // const updatedStrolls = strollsRetrieved.filter(stroll => stroll.startTime !== strollToRemove.startTime)
    // chrome.storage.local.set({'strolls': updatedStrolls})

    //   // If last item associated with focus, delete corresponding focus
    //   if (!strollsRetrieved.find(s => strollToRemove.startTime != s.startTime && strollToRemove.focusId == s.focusId)) {
    //     // Can't find a stroll that (1) isn't the stroll being deleted (2) has the corresponding focus
    //     chrome.storage.local.get('focuses', (res) => {
    //       const focusesRetrieved = res.focuses || []
    //       const updatedFocuses = focusesRetrieved.filter(fo => fo.id != strollToRemove.focusId)
    //       chrome.storage.local.set({ 'focuses': updatedFocuses })
    //     })
    //   }
    // })
  }

  const rename = (strollToRename, focusId) => {    
    // chrome.storage.local.get('strolls', (res) => {
    //   const allStrolls = res.strolls || []
      const updatedStrolls = allStrolls.map(stroll => {
        if (strollToRename.startTime == stroll.startTime) {
          return {
            focusId: focusId,
            startTime: stroll.startTime,
            endTime: stroll.endTime,
            historyItems: stroll.historyItems
          }
        }
        return stroll
      })
      const startOfDay = dayjs(day).startOf('day').valueOf()
      const endOfDay = dayjs(day).endOf('day').valueOf()

      const strollsToday = updatedStrolls.filter(stroll => {
        return ((stroll.startTime >= startOfDay && stroll.startTime < endOfDay) || 
                (stroll.startTime < startOfDay && stroll.endTime >= startOfDay))
      }).sort((a, b) => a.startTime - b.startTime)
      
      const filterFocusIds = filteredFocuses.map(focus => focus.id)
      const filteredStrolls = filterFocusIds.length > 0 ? strollsToday.filter(stroll => filterFocusIds.includes(stroll.focusId)) : strollsToday

      setStrolls(filteredStrolls);
      // chrome.storage.local.set({'strolls': updatedStrolls})
    // })
  }

  return (
    <div className={styles.day} ref={ref}>
        <p className={styles.title}>{dayFormatted}<span className={styles.year}>{year}</span></p>
        <div className={styles.content}>
            <div className={styles.historyItemContainer}>
                {strolls.length > 0 && strolls.find(stroll => stroll.historyItems.length > 0) ? strolls.map((stroll) => {
                    return (
                        <Stroll stroll={stroll} key={`${stroll.startTime}-${stroll.focusId}`} remove={remove} rename={rename} />
                    )
                }) : <p className={styles.emptyHistory}>🌱</p>}
            </div>
        </div>
    </div>
  )
})

export default Day;