import { useState, useEffect, useRef, useContext, useCallback } from 'react'
import styles from '../src/Filter.module.scss'
import { colors } from './Stroll'
import { FilterContext } from './pages/Calendar'
import { allFocuses } from './data'

export default function Filter({ setIsOpen }) {
	const focuses = allFocuses.sort((a, b) => a.name.localeCompare(b.name))
	const inputRef = useRef(null)
	const inputWrapperRef = useRef(null)
	const {filteredFocuses, setFilteredFocuses} = useContext(FilterContext)
	const [suggestedFocuses, setSuggestedFocuses] = useState(allFocuses.sort((a, b) => a.name.localeCompare(b.name)).filter(f => !filteredFocuses.map(fo => fo.id).includes(f.id)))
	const [selectedFocus, setSelectedFocus] = useState(undefined)
	const topGradientRef = useRef(null)
	const bottomGradientRef = useRef(null)
	const containerRef = useRef(null)

	useEffect(() => {
		setTimeout(() => {
			inputRef.current.focus()
		}, 200)
	}, [])

	const topObserver = new IntersectionObserver(entries => {
		topGradientRef.current.style.top = `${50 + inputWrapperRef.current.offsetHeight}`
		if (entries[0] && entries[0].isIntersecting) {
			// First focus is visible, don't show gradient
			topGradientRef.current.style.opacity = 0
		} else {
			// First focus is not visible, show gradient to afford scroll
			topGradientRef.current.style.opacity = 1
		}
	}, {
		threshold: 1.0
	});

	const bottomObserver = new IntersectionObserver(entries => {
		if (entries[0] && entries[0].isIntersecting) {
			// Last focus is visible, don't show gradient
			bottomGradientRef.current.style.opacity = 0
		} else {
			// Last focus is not visible, show gradient to afford scroll
			bottomGradientRef.current.style.opacity = 1
		}
	}, {
		threshold: 1.0
	});

	const firstFocusRef = useCallback(focus => {
        if (focus) {
            topObserver.observe(focus)
        }
    }, [suggestedFocuses])

	const lastFocusRef = useCallback(focus => {
        if (focus) {
            bottomObserver.observe(focus)
        }
    }, [suggestedFocuses])

	const handleChange = (e) => {
		// Clean query of whitespace and convert it to lowercase
		const query = inputRef.current.textContent.trim().toLowerCase()
		if (query.length === 0) {
			// Suggest all focuses that aren't already selected
			setSuggestedFocuses(focuses.filter(focus => !filteredFocuses.includes(focus)))
		} else {
			let relevantFocuses = focuses.filter(focus => {
				const strippedFocus = focus.name.trim().toLowerCase()
				// Query is a substring of words in the title
				return strippedFocus.startsWith(query) || strippedFocus.includes(` ${query}`)
			})
			// Suggest relevant focuses that aren't already selected
			setSuggestedFocuses(relevantFocuses.filter(focus => !filteredFocuses.includes(focus)))
		}
	}

	function getCaretCharacterOffsetWithin(element) {
		var caretOffset = 0;
		var doc = element.ownerDocument || element.document;
		var win = doc.defaultView || doc.parentWindow;
		var sel;
		if (typeof win.getSelection != "undefined") {
			sel = win.getSelection();
			if (sel.rangeCount > 0) {
				var range = win.getSelection().getRangeAt(0);
				var preCaretRange = range.cloneRange();
				preCaretRange.selectNodeContents(element);
				preCaretRange.setEnd(range.endContainer, range.endOffset);
				caretOffset = preCaretRange.toString().length;
			}
		} else if ( (sel = doc.selection) && sel.type != "Control") {
			var textRange = sel.createRange();
			var preCaretTextRange = doc.body.createTextRange();
			preCaretTextRange.moveToElementText(element);
			preCaretTextRange.setEndPoint("EndToEnd", textRange);
			caretOffset = preCaretTextRange.text.length;
		}
		return caretOffset;
	}

	function moveCursorToEnd(el) {
		if(el.innerText && document.createRange)
		{
		  window.setTimeout(() =>
			{
			  let selection = document.getSelection();
			  let range = document.createRange();
	
			  range.setStart(el.childNodes[0],el.innerText.length);
			  range.collapse(true);
			  selection.removeAllRanges();
			  selection.addRange(range);
			}
		  ,1);
		}
	  }

	const handleKeyDown = (e) => {
		if ((e.key === 'Backspace' || e.key === 'Delete') && e.target.textContent.length === 0 && filteredFocuses.length > 0) {
			// Remove most recently added focus
			const deletedFocus = filteredFocuses[filteredFocuses.length - 1]
			const updatedFocuses = filteredFocuses.slice(0,-1)

			// Update filtered focuses in state and storage
			setFilteredFocuses(updatedFocuses)
		
			// Add removed focus back into list of suggestions (sort to retain order)
			setSuggestedFocuses(prevFocuses => prevFocuses.concat([deletedFocus]).sort((a, b) => a.name.localeCompare(b.name)))
		} else if (e.key === 'ArrowLeft' && inputRef.current.previousElementSibling) {
			// Move input one child left
			const input = inputRef.current
			const offset = getCaretCharacterOffsetWithin(input)
			if (offset === 0) {
				input.parentNode.insertBefore(input, input.previousElementSibling)
				input.focus()
			}
		} else if (e.key === 'ArrowRight' && inputRef.current.nextElementSibling) {
			// Move input one child right 
			const input = inputRef.current
			const offset = getCaretCharacterOffsetWithin(input)
			if (offset === input.textContent.length) {
				input.parentNode.insertBefore(input, input.nextElementSibling.nextElementSibling)
				moveCursorToEnd(input)
				input.focus()
			}
		} else if (e.key === 'Enter') {
			e.preventDefault() // Prevent newline from being typed as input
			if (suggestedFocuses.length > 0 && !selectedFocus) {
				addFocus(suggestedFocuses[0].id) // Add first suggested focus, if it exists
			} else if (suggestedFocuses.length > 0 && selectedFocus) {
				addFocus(selectedFocus.id) // Add selected focus
				setSelectedFocus(undefined) // Clear selected focus
			}
		} else if (e.key === 'ArrowUp') {
			if (selectedFocus && suggestedFocuses.length > 0) { // Suggested focuses and selected focus exist
				const index = suggestedFocuses.findIndex(f => f.id === selectedFocus.id) // Grab index of selected focus
				if (index !== -1 && index !== 0) { // If selected focus is not the first suggested focus... 
					setSelectedFocus(suggestedFocuses[index - 1]) // select the previous suggested focus
				} else if (index === 0) { // Otherwise...
					setSelectedFocus(undefined) // unselect focus and return to input field
				}
			}
		} else if (e.key === 'ArrowDown') {
			if (!selectedFocus && suggestedFocuses.length > 0) {
				setSelectedFocus(suggestedFocuses[0]) // Set selected focus to first suggested focus, if it exists
			} else if (suggestedFocuses.length > 0) { // Suggested focuses and selected focus exist
				const index = suggestedFocuses.findIndex(f => f.id === selectedFocus.id) // Grab index of selected focus
				if (index !== -1 && index < suggestedFocuses.length - 1) { // If selected focus is not the last suggested focus...
					setSelectedFocus(suggestedFocuses[index + 1])	// select the next suggested focus
				}
			}
		}
	}
	
	document.addEventListener('click', (e) => {
		let elem = e.target
		while (elem.parentNode) {
			if (elem.id == 'filter') {
				return
			}
			elem = elem.parentNode
		}
		setIsOpen(false)
		setSuggestedFocuses(focuses.filter(f => !filteredFocuses.map(fo => fo.id).includes(f.id)))
	})

	const addFocus = (focusId) => {
		const focus = focuses.find(f => f.id == focusId) // Get focus to add

		// Update filtered focuses in state and storage
		const updatedFocuses = filteredFocuses.concat([focus])
		setFilteredFocuses(updatedFocuses)

		// Suggest focuses that haven't been already added
		setSuggestedFocuses(focuses.filter(f => !updatedFocuses.map(fo => fo.id).includes(f.id)))

		// Clear and focus input
		inputRef.current.textContent = ''
		inputRef.current.focus()
	}

	const handleAddFocus = (e, focusId) => {
		e.stopPropagation() // Prevent click from propagating to toggle filter menu
		addFocus(focusId)
	}
	
	const handleRemoveFocus = (e, focusId) => {
		e.stopPropagation() // Prevent click from propagating to toggle filter menu
		const focus = focuses.find(f => f.id == focusId) // Get focus to remove

		// Update filtered focuses in state and storage
		const updatedFocuses = filteredFocuses.filter(f => f.id != focusId)
		setFilteredFocuses(updatedFocuses)

		// Add removed focus back into list of suggestions (sort to retain order)
		setSuggestedFocuses(prevFocuses => prevFocuses.concat([focus]).sort((a, b) => a.name.localeCompare(b.name)))

		// Focus input
		inputRef.current.focus()
	}

	const focusInput = (e) => {
		if (!e.target.classList.contains(styles.filteredFocusWrapper) && 
				!e.target.classList.contains(styles.focusName) && 
				!e.target.classList.contains(styles.removeFocus)) 
		{
			// Click registered on input wrapper
			inputRef.current.focus()
		}
	}

  return (
	<div className={styles.container} id="filter">
		{focuses.length === 0 &&
			<div>
				<p className={styles.noFilters}>No focuses to filter yet. Go for a stroll and come back later!</p>
			</div>
		}
		{focuses.length > 0 &&
		<>
		<p className={styles.desc}>Filter by</p>
		<div className={styles.inputWrapper} ref={inputWrapperRef} onClick={focusInput}>
			{filteredFocuses && filteredFocuses.map(focus => {
				return (
				<div key={focus.id} style={{backgroundColor: `${colors[focus.color]}46`}} className={styles.filteredFocusWrapper}>
					<p className={styles.focusName} >{focus.name}</p>
					<div className={styles.removeFocus} onClick={(e) => handleRemoveFocus(e, focus.id)}></div>
				</div>
				)
			})}
				<span ref={inputRef} contentEditable="true" className={styles.input} onKeyDown={handleKeyDown} onInput={handleChange}></span>
		</div>
		</>
		}
		{suggestedFocuses && suggestedFocuses.length > 0 &&
		<>
		<div className={styles.topGradient} ref={topGradientRef}></div>
		<div className={styles.focusContainer} ref={containerRef}>
			{suggestedFocuses.map((focus, i) => {
				if (i == 0) {
					return (
						<div key={focus.id} ref={firstFocusRef} className={styles.focus} id={focus.id} onClick={(e) => handleAddFocus(e, focus.id)}>
							<p style={{backgroundColor: `${colors[focus.color]}46`}} className={styles.focusName} >{focus.name}</p>
							{selectedFocus && selectedFocus.id === focus.id && 
								<svg className={styles.focusSelector} xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
									<path fillRule="evenodd" clipRule="evenodd" d="M3.87767 7.93034C4.40925 7.93034 4.86589 7.44321 5.06443 6.83593C5.65116 7.17915 6.37491 7.23247 6.77597 6.83801C7.16964 6.45082 7.1371 5.74562 6.82042 5.15955C7.46667 4.97725 8 4.50309 8 3.94687C8 3.37468 7.4356 2.88933 6.76448 2.71921C7.04627 2.15731 7.06724 1.50401 6.69991 1.13053C6.32024 0.744497 5.63477 0.768293 5.0557 1.06796C4.85268 0.473048 4.40162 0 3.87778 0C3.36017 0 2.91361 0.461868 2.7072 1.04679C2.15646 0.787413 1.52933 0.777704 1.16642 1.13464C0.784383 1.51039 0.803733 2.18563 1.09462 2.76083C0.487248 2.95933 0 3.41601 0 3.94766C0 4.44699 0.429811 4.88019 0.985276 5.09547C0.699862 5.65907 0.677036 6.31658 1.04602 6.69174C1.42109 7.07308 2.09456 7.05449 2.66909 6.7651C2.85432 7.40488 3.32557 7.93034 3.87767 7.93034Z" fill={`${colors[focus.color]}`}/>
								</svg>
							}
						</div>
					)
				}
				else if (i == suggestedFocuses.length - 1) {
					return (
					<div key={focus.id} ref={lastFocusRef} className={styles.focus} id={focus.id} onClick={(e) => handleAddFocus(e, focus.id)}>
						<p style={{backgroundColor: `${colors[focus.color]}46`}} className={styles.focusName} >{focus.name}</p>
						{selectedFocus && selectedFocus.id === focus.id && 
							<svg className={styles.focusSelector} xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
								<path fillRule="evenodd" clipRule="evenodd" d="M3.87767 7.93034C4.40925 7.93034 4.86589 7.44321 5.06443 6.83593C5.65116 7.17915 6.37491 7.23247 6.77597 6.83801C7.16964 6.45082 7.1371 5.74562 6.82042 5.15955C7.46667 4.97725 8 4.50309 8 3.94687C8 3.37468 7.4356 2.88933 6.76448 2.71921C7.04627 2.15731 7.06724 1.50401 6.69991 1.13053C6.32024 0.744497 5.63477 0.768293 5.0557 1.06796C4.85268 0.473048 4.40162 0 3.87778 0C3.36017 0 2.91361 0.461868 2.7072 1.04679C2.15646 0.787413 1.52933 0.777704 1.16642 1.13464C0.784383 1.51039 0.803733 2.18563 1.09462 2.76083C0.487248 2.95933 0 3.41601 0 3.94766C0 4.44699 0.429811 4.88019 0.985276 5.09547C0.699862 5.65907 0.677036 6.31658 1.04602 6.69174C1.42109 7.07308 2.09456 7.05449 2.66909 6.7651C2.85432 7.40488 3.32557 7.93034 3.87767 7.93034Z" fill={`${colors[focus.color]}`}/>
							</svg>
						}
					</div>
					)
				}
				return (
					<div key={focus.id} className={styles.focus} id={focus.id} onClick={(e) => handleAddFocus(e, focus.id)}>
						<p style={{backgroundColor: `${colors[focus.color]}46`}} className={styles.focusName} >{focus.name}</p>
						{selectedFocus && selectedFocus.id === focus.id && 
							<svg className={styles.focusSelector} xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
								<path fillRule="evenodd" clipRule="evenodd" d="M3.87767 7.93034C4.40925 7.93034 4.86589 7.44321 5.06443 6.83593C5.65116 7.17915 6.37491 7.23247 6.77597 6.83801C7.16964 6.45082 7.1371 5.74562 6.82042 5.15955C7.46667 4.97725 8 4.50309 8 3.94687C8 3.37468 7.4356 2.88933 6.76448 2.71921C7.04627 2.15731 7.06724 1.50401 6.69991 1.13053C6.32024 0.744497 5.63477 0.768293 5.0557 1.06796C4.85268 0.473048 4.40162 0 3.87778 0C3.36017 0 2.91361 0.461868 2.7072 1.04679C2.15646 0.787413 1.52933 0.777704 1.16642 1.13464C0.784383 1.51039 0.803733 2.18563 1.09462 2.76083C0.487248 2.95933 0 3.41601 0 3.94766C0 4.44699 0.429811 4.88019 0.985276 5.09547C0.699862 5.65907 0.677036 6.31658 1.04602 6.69174C1.42109 7.07308 2.09456 7.05449 2.66909 6.7651C2.85432 7.40488 3.32557 7.93034 3.87767 7.93034Z" fill={`${colors[focus.color]}`}/>
							</svg>
						}
					</div>
				)
			})}
		</div>
		<div className={styles.bottomGradient} ref={bottomGradientRef}></div>
		</>
		}
	</div>
  )
}
