import { useState, useEffect } from 'react'
import styles from './HistoryItem.module.scss'
import { allHighlights, allCaptures } from './data'

export default function HistoryItem({ title, url, visitTime, startTime, endTime }) {
    let faviconSrc = ''
    if (url.startsWith('https://www.google.com')) {
        faviconSrc = `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${url}/&size=64`
    } else {
        faviconSrc = `https://s2.googleusercontent.com/s2/favicons?domain_url=${url}&sz=64`
    }
    const [highlights, setHighlights] = useState([])
    const [captures, setCaptures] = useState([])

    const baseUrl = url.split('#')[0]

    useEffect(() => {
        let page = allHighlights.find(page => page.url == baseUrl)
        if (page && page.highlights) {
            let relevantHighlights = []
            page.highlights.map(highlight => {
                // if (startTime <= highlight.createdTime && highlight.createdTime <= endTime) {
                    relevantHighlights.push(highlight)
                // }
            })
            setHighlights(relevantHighlights)
        }

        page = allCaptures.find(page => page.url == baseUrl)
            if (page && page.captures) {
            let relevantCaptures = []
            page.captures.map(capture => {
                // if (startTime <= capture.createdTime && capture.createdTime <= endTime) {
                    relevantCaptures.push(capture)
                // }
            })
            setCaptures(relevantCaptures)
        }
    }, [])

    return (
        <div className={styles.container} id={`item-${visitTime}`}>
            <a className={styles.metadata} href={url} target="_blank">
                <img className={styles.favicon} src={faviconSrc} alt="Favicon" />
                <p className={styles.title}>{title}</p>
            </a>
            
                {captures.length > 0 &&
                <div className={styles.captureContainer} id={`capture-container-${startTime}`}>
                    {captures.map((capture, i) => {
                        return (
                            <div className={styles.captureContainer} key={capture.dataUrl} id={`capture-${capture.dataUrl}`}
                                onClick={(e) => {
                                    if (!document.getElementById(`zoomed-image-${capture.dataUrl}`)) {
                                        e.stopPropagation()
                                    
                                        const zoomedImage = document.createElement('img')
                                        zoomedImage.id = `zoomed-image-${capture.dataUrl}`
                                        zoomedImage.classList.add(styles.capture)
                                        zoomedImage.classList.add(styles.captureZoom)
                                        zoomedImage.src = `/images/captures/${capture.dataUrl}.png`
                                        zoomedImage.style.backgroundColor = capture.backgroundColor
    
                                        const captureDimensions = document.getElementById(`capture-${capture.dataUrl}`).getBoundingClientRect()
                                        const centerY = captureDimensions.top + (captureDimensions.height * 0.5)
                                        const centerX = captureDimensions.left + (captureDimensions.width * 0.5)
                                        const centerYScreen = window.innerHeight * 0.5
                                        const centerXScreen = window.innerWidth * 0.5
                                        let expandedWidth = 0
                                        let expandedHeight = 0
                                        const isLandscape = captureDimensions.width > captureDimensions.height
                                        if (isLandscape) { // Landscape image
                                            expandedWidth = document.body.clientWidth * 0.5
                                            expandedHeight = expandedWidth * (captureDimensions.height / captureDimensions.width)
                                        } else { // Portrait image
                                            expandedHeight = document.body.clientHeight * 0.5
                                            expandedWidth = expandedHeight * (captureDimensions.width / captureDimensions.height)
                                        }
                                        const portraitOffset = (isLandscape ? 0 : 30) // Body has 60px less height (due to margin) than viewport
                                        const dy = centerYScreen - centerY - (expandedHeight * 0.5) - portraitOffset
                                        const dx = centerXScreen - centerX - (expandedWidth * 0.5) - portraitOffset
    
                                        zoomedImage.style.top = `${centerY}px`
                                        zoomedImage.style.left = `${centerX}px`
    
                                        document.body.addEventListener('click', () => {
                                            if (document.getElementById(`zoomed-image-${capture.dataUrl}`)) {
                                                zoomedImage.style.transform = ''
                                                zoomedImage.style.opacity = 0
                                                zoomedImage.style.width = '0px'
                                                setTimeout(() => {
                                                    document.body.removeChild(document.getElementById(`zoomed-image-${capture.dataUrl}`))
                                                }, 300)
                                                
                                            }
                                        })
                                        
                                        document.body.insertBefore(zoomedImage, document.body.firstChild)
                                        setTimeout(() => {
                                            zoomedImage.style.opacity = 1
                                            zoomedImage.style.transform = `translateY(${dy}px) translateX(${dx}px)`
                                            zoomedImage.style.width = `${expandedWidth}px`
                                        }, 50)
                                    }
                                }}
                            >
                                <img className={styles.capture} src={`/images/captures/${capture.dataUrl}.png`} style={{backgroundColor: capture.backgroundColor}}
                                />
                                {/* <p className={styles.captureDate}>{dayjs(capture.createdTime).format('h A')}</p> */}
                            </div>
                        )
                    })}
                </div>
            }
            
            {highlights.length > 0 && 
            <div className={styles.highlightContainer}>
                {highlights && highlights.map((highlight, i) => {
                    return (
                        <div className={styles.highlightWrapper} key={highlight.text}>
                            <p className={styles.highlight}>{highlight.text}</p>
                        </div>
                    )
                })}
            </div>
            }
        </div>
  )
}
