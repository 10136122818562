import { createRoot } from 'react-dom/client';
import { Analytics } from '@vercel/analytics/react';
import Calendar from './pages/Calendar'

export default function App() {
    return (
        <>
            <Calendar/>
            <Analytics />
        </>
    )
}

const root = createRoot(document.getElementById('root'))
root.render(<App/>)